import { Freight, Place } from '@app/core/models/freight.model';
import {
	FreightResponse,
	PaginatedResponse,
	SearchFreightResponse,
} from '@app/core/models/freight-response.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@env/environment';

@Injectable()
export class FreightService {
	private URL: string = environment.apiUrl + 'freights/';

	private reloadFreightsSubject = new Subject<void>();
	reloadFreights$ = this.reloadFreightsSubject.asObservable();

	private freightHistorySubject = new Subject<{
		open: boolean;
		freight: Freight | null | undefined;
	}>();
	freightHistory$ = this.freightHistorySubject.asObservable();

	constructor(private http: HttpClient) {}

	openFreightHistory(freight: Freight | null | undefined) {
		this.freightHistorySubject.next({ open: true, freight });
	}

	closeFreightHistory() {
		this.freightHistorySubject.next({
			open: false,
			freight: undefined,
		});
	}

	reloadFreights() {
		this.reloadFreightsSubject.next();
	}

	getFreights(): Observable<FreightResponse> {
		return this.http.get(this.URL + 'get-freights').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<FreightResponse>;
				return of(res.data);
			}),
		);
	}

	getOrigins(description: string): Observable<Place[]> {
		return this.http.get(`${this.URL}origins?description=${description}`).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Place[]>;
				return of(res.data);
			}),
		);
	}

	getDestinations(description: string): Observable<Place[]> {
		return this.http
			.get(`${this.URL}destinations?description=${description}`)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<Place[]>;
					return of(res.data);
				}),
			);
	}

	addOrigin(origin: Place): Observable<number> {
		return this.http.post(`${this.URL}add-origin`, origin).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<number>;
				return of(res.data);
			}),
		);
	}

	addDestination(destination: Place): Observable<number> {
		return this.http.post(`${this.URL}add-destination`, destination).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<number>;
				return of(res.data);
			}),
		);
	}

	getFreightById(freightId: number): Observable<Freight> {
		return this.http.get(this.URL + 'get-freight?freightId=' + freightId).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Freight>;
				return of(res.data);
			}),
		);
	}

	getFreightToRateById(freightId: number): Observable<Freight> {
		return this.http
			.get(this.URL + 'get-freight-rate?freightId=' + freightId)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<Freight>;
					return of(res.data);
				}),
			);
	}

	searchFreights(filters: any): Observable<PaginatedResponse<Freight[]>> {
		return this.http.post(this.URL + 'search-freights', filters).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<PaginatedResponse<Freight[]>>;
				return of(res.data);
			}),
		);
	}

	getFavoriteFreights(filters: any): Observable<PaginatedResponse<Freight[]>> {
		return this.http.post(this.URL + 'favorite-freights', filters).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<PaginatedResponse<Freight[]>>;
				return of(res.data);
			}),
		);
	}

	publishFreight(request: any): Observable<boolean> {
		return this.http.post(this.URL + 'publish-freight', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(response.data);
			}),
		);
	}

	updateFreight(request: any): Observable<boolean> {
		return this.http.post(this.URL + 'update-freight', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(response.data);
			}),
		);
	}

	archiveFreight(freightId: number): Observable<boolean> {
		return this.http
			.get(this.URL + 'archive-freight?FreightId=' + freightId)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<boolean>;
					return of(response.data);
				}),
			);
	}

	deleteFreight(freightId: number): Observable<boolean> {
		return this.http
			.get(this.URL + 'delete-freight?FreightId=' + freightId)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<boolean>;
					return of(response.data);
				}),
			);
	}

	publicFreight(freightId: number): Observable<boolean> {
		return this.http
			.get(this.URL + 'public-freight?FreightId=' + freightId)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<boolean>;
					return of(response.data);
				}),
			);
	}

	asignFreight(request: {
		driverFirstName: string;
		driverLastName: string;
		driverPhone: string;
		terms: boolean;
		freightId: number;
		carrierId: number;
	}): Observable<boolean> {
		return this.http.post(this.URL + 'asign-freight', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(response.data);
			}),
		);
	}

	responseFreight(request: {
		response: string;
		freightId: number;
	}): Observable<boolean> {
		return this.http.post(this.URL + 'response-freight', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	/**
	 * Add or remove from favorite a freight
	 * @param freightId Identity number of Freight
	 * @returns Boolean `True` if can removed or added the freight.
	 */
	favoriteFreight(freightId: number): Observable<boolean> {
		return this.http.post(this.URL + 'favorite', { freightId: freightId }).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(response.data);
			}),
		);
	}

	pickupFreight(request: {
		date: any;
		freightId: number;
	}): Observable<boolean> {
		return this.http.post(this.URL + 'pick-up', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(response.data);
			}),
		);
	}
	deliveredFreight(request: {
		date: any;
		freightId: number;
	}): Observable<boolean> {
		return this.http.post(this.URL + 'delivered', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(response.data);
			}),
		);
	}
}

